import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [{
    path: '/',
    name: 'Manager',
    component: () => import('../views/Manager.vue'),
    redirect: '/home',  // 重定向到主页
    children: [{
        path: '403',
        name: 'NoAuth',
        meta: {name: '无权限'},
        component: () => import('../views/manager/403')
    }, {
        path: 'home',
        name: 'Home',
        meta: {name: '系统首页'},
        component: () => import('../views/manager/Home')
    }, {
        path: 'user',
        name: 'User',
        meta: {name: '用户管理'},
        component: () => import('../views/manager/User')
    }, {
        path: 'password',
        name: 'Password',
        meta: {name: '修改密码'},
        component: () => import('../views/manager/Password')
    }, {
        path: 'notice',
        name: 'Notice',
        meta: {name: '公告信息'},
        component: () => import('../views/manager/Notice')
    }, {
        path: 'permission',
        name: 'Permission',
        meta: {name: '权限管理'},
        component: () => import('../views/manager/Permission')
    }, {
        path: 'supplier',
        name: 'Supplier',
        meta: {name: '供应商管理'},
        component: () => import('../views/manager/Supplier')
    }, {
        path: 'customer',
        name: 'Customer',
        meta: {name: '客户管理'},
        component: () => import('../views/manager/Customer')
    }, {
        path: 'goods',
        name: 'Goods',
        meta: {name: '产品管理'},
        component: () => import('../views/manager/Goods')
    }, {
        path: 'productStock',
        name: 'ProductStock',
        meta: {name: '产品库存管理'},
        component: () => import('../views/manager/ProductStock')
    }, {
        path: 'productType',
        name: 'ProductType',
        meta: {name: '产品分类管理'},
        component: () => import('../views/manager/ProductType')
    },
        {
            path: 'stock',
            name: 'Stock',
            meta: {name: '采购管理'},
            component: () => import('../views/manager/Stock')
        }, {
            path: 'back',
            name: 'Back',
            meta: {name: '采购退货管理'},
            component: () => import('../views/manager/Back')
        }, {
            path: 'sale',
            name: 'Sale',
            meta: {name: '销售管理'},
            component: () => import('../views/manager/Sale')
        }, {
            path: 'saleBack',
            name: 'SaleBack',
            meta: {name: '销售退货管理'},
            component: () => import('../views/manager/SaleBack')
        }, {
            path: 'logs',
            name: 'Logs',
            meta: {name: '系统日志'},
            component: () => import('../views/manager/Logs')
        }, {
            path: 'userPerson',
            name: 'UserPerson',
            meta: {name: '个人信息'},
            component: () => import('../views/manager/UserPerson')
        }, {
            path: 'department',
            name: 'Department',
            meta: {name: '部门管理'},
            component: () => import('../views/manager/Department')
        }, {
            path: 'position',
            name: 'Position',
            meta: {name: '岗位管理'},
            component: () => import('../views/manager/Position')
        }, {
            path: 'message',
            name: 'Message',
            meta: {name: '消息中心'},
            component: () => import('../views/manager/Message.vue')
        },
    ]
}, {
    path: '/login',
    name: 'Login',
    meta: {name: '登录'},
    component: () => import('../views/Login.vue')
}, {
    path: '/register',
    name: 'Register',
    meta: {name: '注册'},
    component: () => import('../views/Register.vue')
}, {
    path: '*',
    name: 'NotFound',
    meta: {name: '无法访问'},
    component: () => import('../views/404.vue')
},]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
export default router
